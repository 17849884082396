<template>
  <div v-if="isReady" class="row">
    <div v-if="hasSendgridIntegration" class="col-md-12">
      <b-tabs v-model="activeTabIndex">
        <b-tab title="SMS" @click="onTabClick('sms')">
          <List v-if="activeTabIndex === 0" provider="sms"/>
        </b-tab>
        <b-tab title="Email" @click="onTabClick('email')">
          <List v-if="activeTabIndex === 1" provider="email"/>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else class="col-md-12">
      <List v-if="activeTabIndex === 0" provider="sms"/>
    </div>
  </div>
</template>

<script>
import List from './Components/List.vue'

export default {
  components: {
    List
  },

  data() {
    return {
      isReady: false,
      activeTabIndex: 0,
      provider: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    
    hasSendgridIntegration() {
      return this.user && this.user.business && this.user.business.has_integrations.sendgrid
    },
  },

  mounted() {
    this.provider = this.$route.query.type

    if (this.provider === 'email')
    {
      this.$nextTick(() => {
        this.activeTabIndex = 1
        this.isReady = true
      })
    } else {
      this.activeTabIndex = 0
      this.isReady = true
    }
  },

  methods: {
    onTabClick(type)
    {
      let provider = this.activeTabIndex === 0 ? 'sms' : 'email'
      if (provider !== type) {
        this.$store.dispatch('broadcast/resetAll')
        .then(() => {
          let provider = this.activeTabIndex === 0 ? 'sms' : 'email'
          this.$router.replace({ name: 'business.broadcasts.index', query: { type: provider} })
          })
      }
    }
  },
}
</script>

<style lang="scss">

</style>